@import 'normalize.css';
body {
    font-family: "IBMPlexSans", "ChevinPro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
#webbot {
    margin: 0;
    font-family: "IBMPlexSans", "ChevinPro", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#webbot,
.ant-modal-root {
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    p {
        margin: 0;
        padding: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 400 !important;
    }

    h3.ant-typography {
        @media screen and (max-width: 1024px) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .app {
        &-main {
            max-width: 1000px;
            margin: 0 auto;
        }
        &__loading {
            margin: 0 auto;
            width: 100%;
        }
    }
    .teams {
        &__title {
        }
        &__table {
            margin-top: 25px;
        }
        &-confirm {
            &__title {
                text-align: left;
            }
            &__list {
                margin-top: 30px;
                & .ant-list-item-meta-title {
                    margin-top: 6px;
                }
            }
            &__buttons {
                padding-top: 15px;
            }
            &__confirm {
                margin-right: 15px;
                margin-top: 15px;
            }
            &__cancel {
                margin-top: 15px;
            }
        }
    }
    .accelerators {
        &__title {
        }
        &__button {
            margin-top: 30px;
        }
        &__list {
            &-radio {
                display: block;
                margin-top: 10px;
            }
        }
    }
    .tasks {
        &__title {
        }
        &-list {
            margin-top: 30px;
            &__item {
                padding-top: 10px;
            }
        }
        &__button {
            margin-top: 30px;
        }
    }
    .members {
        &__title {
        }
        &-list {
            margin-top: 30px;
            &__item {
                padding-top: 10px;
            }
        }
        &__button {
            margin-top: 30px;
        }
    }
    .assessment {
        &__title {
        }
        &-member {
            &__name {
                margin-top: 30px;
            }
        }
        &-member {
            &__table {
                margin-top: 10px;
                &-rate {
                    display: table;
                    margin: 0 auto;
                    text-align: center;
                    &-text {
                        text-align: center;
                    }
                }
            }
            &__interaction {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                padding: 30px 8px 0 8px;
                margin-top: 30px;
                border-top: 1px solid #eee;
                &-title {
                }
                &-rate {
                    &-container {
                        margin-left: 30px;
                    }
                    &-text {
                        text-align: center;
                    }
                }
            }
        }
        &__button {
            margin-top: 30px;
        }
        &-tasks {
            padding-top: 10px;
        }
        &-task {
            border: 1px solid #eee;
            margin-top: 10px;
            &__header {
                padding: 15px;
                @media screen and (max-width: 1024px) {
                    padding: 10px;
                }
            }
            &__title {
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
            }
            &__body {
                padding: 15px;
                border-top: 1px solid #eee;
                @media screen and (max-width: 1024px) {
                    padding: 10px;
                }
            }
            &__add {
                &-select {
                    width: 100%;
                }
            }
            &__assessments {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
            }
            &__assessment {
                flex-basis: 20%;
                margin-top: 20px;
                @media screen and (max-width: 1024px) {
                    flex-basis: 33%;
                }
                @media screen and (max-width: 479px) {
                    flex-basis: 50%;
                }
                &-avatar {
                    margin: 0 auto;
                    display: table;
                    position: relative;
                    overflow: inherit;
                }
                &-remove {
                    position: absolute;
                    top: -5px;
                    right: -20px;
                    color: #000;
                    background: #eee;
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    overflow: hidden;
                    & .anticon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        cursor: pointer;
                        font-size: 12px;
                    }
                }
                &-rate {
                    text-align: center;
                    width: 100%;
                    margin-top: 5px;
                    & .ant-rate-star {
                        & .anticon {
                            font-size: 16px;
                        }
                    }
                }
            }
            &__button {
                margin-top: 30px;
            }
        }
        &-modal {
            &__member {
                text-align: center;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
            }
            &__task {
                text-align: center;
                margin-top: 10px;
            }
            &__rate {
                margin-top: 20px;
                text-align: center;
                &-rate {
                    width: 100%;
                    & .ant-rate-star {
                        & .anticon {
                            font-size: 30px;
                        }
                    }
                }
                &-title {
                    width: 100%;
                }
            }
            &__buttons {
                display: flex;
                justify-content: center;
                text-align: center;
                margin-top: 30px;
            }
            &__button {
                &:nth-child(2) {
                    margin-left: 15px;
                }
            }
        }
    }
}

